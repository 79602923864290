'use strict';
$(document).ready(function($) {
   $(document).on("click", '.prof-view-more', function (event) {
      var link = $(this).data('href');
      window.location = link;
   });

   $(document).on("click", '.prof-panel-results .pagination a', function (event) {
      if (!$(this).hasClass('disabled')) {
         var containers = $(this).parents(".prof-panel-results");
         if (containers.length) {
            var container = containers[0];
            var pid = $(container).data('panelid');
            if(window.aPanelAgents != undefined){
               $(container).load('/professionals/widget/panelresults', {
                  agentguids: window.aPanelAgents[pid],
                  page: parseInt($(this).data('page'))+1,
                  pageSize: window.aPanelPageSizes[pid]
               });

               var panels = $(container).parents(".prof-grid-panel");
               if (panels.length) {
                  var paneltops = $(panels[0]).find(".paneltop");
                  if(paneltops.length){
                     window.location = "#" + $(paneltops[0]).attr('id');
                  }
               }
            }else{
               alert('oh noes');
            }
         }
      }
      event.stopPropagation();
      return false;
   });

   $(document).on("click", '.prof-slide-view', function(event){
      if(window.slideshowsHalted == undefined){
         window.slideshowsHalted = [];
      }
      var persons = $(this).parents('.person-item');
      var feeder = $(this).parents('.prof-slide-feeder');
      var slide = null;
      if(feeder.length) {
         slide = $("#" + feeder.data('target'));
      }
      if(persons.length && slide != null && slide.length){
         var person = $(persons[0]);
         if(!(window.slideshowsHalted.indexOf(slide.data('showid'))>=0)) {
            window.slideshowsHalted.push(slide.data('showid'));
         }
         slide.fadeOut("slow", "swing", function(){setProfessionalSlide(person, slide)});
      }
   });

   function setProfessionalSlide(person, slide){
      if (person != undefined && slide != undefined){
         slide.data('activeslide', person.data('slidenum'));
         slide.find('.prof-slide-thumbnail').attr('src', person.data('thumbnail'));
         slide.find('.prof-slide-printedname').html(person.data('printedname'));
         slide.find('.prof-slide-officetitle').html(person.data('primaryTitle'));
         slide.find('.prof-slide-designations').html(person.data('designations'));
         if (person.data('licensenumbers') !== '') {
            slide.find('.prof-slide-licensenumbers').html(person.data('licensenumbers'));
         } else {
            slide.find('.prof-slide-licensenumbers').html('');
         }
         var officename = person.data('officenameline1') + "<br/>" + person.data('officenameline2');
         slide.find('.prof-slide-officename').html(officename);
         var address1 = person.data('address1');
         var address2 = person.data('address2');
         var address3 = person.data('address3');
         var city = person.data('city');
         var state = person.data('state');
         var postalcode = person.data('postalcode');
         var strAddress = address1 + "<br/>";
         strAddress += (address2!=null && address2!="") ? address2 + "<br/>" : "";
         strAddress += (address3!=null && address3!="") ? address3 + "<br/>" : "";
         strAddress += city + ", " + state + " " + postalcode;
         slide.find('.prof-slide-address').html(strAddress);
         var primary = person.data('phone-primary');
         var mobile = person.data('phone-mobile');
         var fax = person.data('phone-fax');
         var pCnt = 0;
         var tablebody = "<tbody>";
         if(primary != null && primary != ""){
            pCnt++;
            tablebody += '<td class="phone-icon"><i class="glyphicon glyphicon-earphone"></i></td>';
            tablebody += "<td>"+primary+"</td>";
         }
         if(mobile != null && mobile != ""){
            pCnt++;
            tablebody += '<td class="phone-icon"><i class="glyphicon glyphicon-phone"></i></td>';
            tablebody += "<td>"+mobile+"</td>";
         }
         if(pCnt == 2){
            tablebody += "</tr><tr>";
         }
         if((pCnt%2) == 1){
            tablebody += "<td></td><td></td></tr>";
         }else{
            tablebody += "</tr>";
         }
         tablebody += "</tbody>";
         slide.find('.prof-slide-phone').html(tablebody);
         slide.find('.prof-view-more').attr("data-href", person.data('profile-url'));
      }
      slide.fadeIn("slow", "swing");
   }

   function incrementProfessionalSlides(){
      if(window.slideshowsHalted == undefined){
         window.slideshowsHalted = [];
      }
      $('.prof-slide').each(function(index){
         var newShowId = 1;
         var showid = $(this).data('showid');

         if(!(window.slideshowsHalted.indexOf(showid)>=0)){
            var activeslide = $(this).data('activeslide');
            var source = $('#prof-slide-feeder'+showid);
            if(source.length){
               var newSlide = source.find('.slide'+(parseInt(activeslide)+1));
               if(newSlide.length){
                  //we found the next slide
                  newShowId = parseInt(activeslide)+1;
               }else{
                  //revert to the first slide
                  newSlide = source.find('.slide1');
                  newShowId = 1;
               }
               activeslide = $(this).data('activeslide', newShowId);
               $(this).fadeOut("slow", function(){setProfessionalSlide(newSlide, $(this))});
            }

         }
      });
      setTimeout(incrementProfessionalSlides, 5000);
   }

   setTimeout(incrementProfessionalSlides, 9000);


});